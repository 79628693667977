import React from 'react'
import { graphql } from 'gatsby'
import './News.scss'
import HeroBanner from '../../components/HeroBanner'
import AlternatingContent from '../../components/AlternatingContent'
import Layout from '../../components/Layout'
import { GenericWordpressToObject } from '../../util/WordpressUtil';

const News = ({data}) => {

  return (
    <Layout>
    <div className="News">
      <HeroBanner
        image={data.file.childImageSharp.fluid}
        title="OCR in the Wild"
        caption="Whether it's reviews or in the news, here's what people are saying about us."
      />
      <div className="container content">
        <h2>The News</h2>
        <AlternatingContent items={data.allWordpressWpNewsItem.edges.map(GenericWordpressToObject)} />
{/* 
        <h2>Reviews</h2>
        <iframe
          scrolling="yes"
          title="frederick-widget"
          className="reviewFrame"
          id="iFrameResizer0"
          src="https://hirefrederick.com/widget/review?location_id=c8402e7a-15e6-4b5d-ab3a-839318e653e2&amp;per_page=5&amp;layout=list&amp;background=ffffff&amp;title_hex_color=2B2B2B&amp;star_hex_color=fcc415"
        /> */}
      </div>
    </div>
    </Layout>
  )
}

export default News

export const query = graphql`
{
  allWordpressWpNewsItem(sort: {order: ASC, fields: order}) {
    edges {
      node {
        title
        content
        featured_media {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
  file(url: { eq: "https://api.ocracademy.ca/wp/wp-content/uploads/2019/08/academy-small.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
}
`